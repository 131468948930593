var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _vm._l(_vm.detailFields, function (item, index) {
                    return _c("details-card", {
                      key: index,
                      attrs: {
                        header: item.header,
                        edit: item.edit || false,
                        fields: item.fields,
                        beForm: _vm.beForm,
                        data: _vm.details,
                        repository: item.repository,
                      },
                    })
                  }),
                  _vm._v(" "),
                  _vm.checkTreasuryRelation.length
                    ? _c(
                        "b-card",
                        {
                          staticClass: "mt-2",
                          attrs: { header: "true", "header-tag": "header" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 d-inline-block align-middle",
                                },
                                [_c("b", [_vm._v("Forme di Pagamento")])]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-card-text",
                            [
                              _c("b-table", {
                                attrs: {
                                  striped: "",
                                  hover: "",
                                  items: _vm.entryDetails,
                                  fields: _vm.entryDetailFields,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(treasury_code)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(row.item?.treasury?.code) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(treasury_title)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                row.item?.treasury?.title
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(amount)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  row.item.gross
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2914935885
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isDelegaNostra
                    ? _c(
                        "div",
                        _vm._l(_vm.coassFields, function (item, index) {
                          return _c("details-card", {
                            key: index,
                            attrs: {
                              header: item.header,
                              edit: item.edit || false,
                              fields: item.fields,
                              beForm: _vm.beForm,
                              data: _vm.details,
                              repository: item.repository,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.coassDetails.length && _vm.isDelegaNostra
                    ? _c(
                        "b-card",
                        {
                          staticClass: "mt-2",
                          attrs: { header: "true", "header-tag": "header" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 d-inline-block align-middle",
                                },
                                [_c("b", [_vm._v("Dettaglio Coassicurazioni")])]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-card-text",
                            [
                              _c("b-table", {
                                attrs: {
                                  striped: "",
                                  hover: "",
                                  items: _vm.coassDetails,
                                  fields: _vm.coassDetailFields,
                                  responsive: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(compagnia_delegata)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(row.item.code_importer) +
                                              " - " +
                                              _vm._s(row.item.title_importer) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(agenzia_delegata)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(row.item.code) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(descrizione)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(row.item.title) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(participant_prov_purchase)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  row.item.pivot
                                                    .participant_prov_purchase
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(participant_prov_take)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  row.item.pivot
                                                    .participant_prov_take
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(net)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  row.item.pivot.net
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(tax)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  row.item.pivot.tax
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(gross)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.toLocaleCurrency(
                                                  row.item.pivot.gross
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4181675754
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }