var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2" }, [
    _c(
      "div",
      { style: { width: !_vm.noInnerWidth ? _vm.width : "auto" } },
      [
        _c(
          "div",
          [
            _c(
              "b-table",
              {
                attrs: {
                  busy: _vm.isLoading,
                  "no-local-sorting": true,
                  items: _vm.items,
                  fields: _vm.getAllFields,
                  "current-page": _vm.currentPage,
                  "per-page": "0",
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  "sort-direction": _vm.sortDirection,
                  "sticky-header": _vm.height,
                  "no-border-collapse": "",
                  "show-empty": "",
                  "empty-text": "Non ci sono informazioni da mostrare",
                  small: "",
                },
                on: {
                  "update:sortBy": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function ($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function ($event) {
                    _vm.sortDesc = $event
                  },
                  "sort-changed": _vm.sortingChanged,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center my-2" },
                            [
                              _c("base-icon", {
                                attrs: {
                                  name: "loading",
                                  width: "35",
                                  height: "35",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", [_vm._v("Operazione in corso...")]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "cell(additional_description)",
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                item?.payment_reference?.code
                                  ? `${item.payment_reference.code} - ${item.additional_description}`
                                  : item.additional_description
                              ) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                    _vm.items.length
                      ? {
                          key: "cell(customer)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    item.registries.length
                                      ? item.registries[0].status_registry
                                          .value === 1
                                        ? item.registries[0].attributables.CNAM
                                        : item.registries[0].attributables
                                            .SURN +
                                          " " +
                                          item.registries[0].attributables.NAME
                                      : ""
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(gross_coass)",
                          fn: function ({ item }) {
                            return [
                              !item.insurance_ancillaries[0]
                                ?.insurer_participants.length
                                ? _c("span", [
                                    _vm._v("\n            No\n          "),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                item.insurance_ancillaries[0]
                                  ?.insurer_participants,
                                function (insurer_participant, index) {
                                  return _c("span", { key: index }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          insurer_participant.code +
                                            "-" +
                                            insurer_participant.title +
                                            ": " +
                                            _vm.toLocaleCurrency(
                                              insurer_participant.pivot.gross
                                            )
                                        )
                                    ),
                                    _c("br"),
                                  ])
                                }
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(insurer_description)",
                          fn: function ({ item }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      item.insurer
                                        ? `${item.insurer.code}-${item.insurer.title}`
                                        : ""
                                    ) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(document_code)",
                          fn: function ({ item }) {
                            return _vm._l(
                              item.book_tags,
                              function (book_tag, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(`${book_tag.code}`) +
                                      "\n          "
                                  ),
                                ])
                              }
                            )
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(take)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.taking(item)) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(insurance_policy_number)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    item.various_accountings &&
                                      item.various_accountings.length
                                      ? item.various_accountings[0].pivot
                                          .insurance_policy_number
                                      : item.insurance_ancillaries &&
                                        item.insurance_ancillaries.length
                                      ? item.insurance_ancillaries[0]
                                          .insurance_policy
                                        ? item.insurance_ancillaries[0]
                                            .insurance_policy.number
                                        : ""
                                      : ""
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(branch)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    item.insurance_ancillaries &&
                                      item.insurance_ancillaries.length
                                      ? item.insurance_ancillaries[0]
                                          .insurance_policy
                                        ? item.insurance_ancillaries[0]
                                            .insurance_policy.insurance_risk
                                          ? `${item.insurance_ancillaries[0].insurance_policy.insurance_risk.risk_branch.code}-${item.insurance_ancillaries[0].insurance_policy.insurance_risk.code}-
                  ${item.insurance_ancillaries[0].insurance_policy.insurance_risk.risk_branch.formatted_title}`
                                          : ""
                                        : ""
                                      : item.various_accountings &&
                                        item.various_accountings.length
                                      ? item.various_accountings[0].pivot
                                          .insurance_risk
                                        ? `${item.various_accountings[0].pivot.insurance_risk.risk_branch.code}-${item.various_accountings[0].pivot.insurance_risk.code}-
                ${item.various_accountings[0].pivot.insurance_risk.risk_branch.formatted_title}
                `
                                        : ""
                                      : ""
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(product)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    item.insurance_ancillaries &&
                                      item.insurance_ancillaries.length
                                      ? item.insurance_ancillaries[0]
                                          .insurance_policy
                                        ? item.insurance_ancillaries[0]
                                            .insurance_policy.insurance_risk
                                            .formatted_title
                                        : ""
                                      : item.various_accountings &&
                                        item.various_accountings.length
                                      ? item.various_accountings[0].pivot
                                          .insurance_risk
                                        ? item.various_accountings[0].pivot
                                            .insurance_risk.formatted_title
                                        : ""
                                      : ""
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(saler_gross)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.toLocaleCurrency(
                                      item.saler_prov_purchase +
                                        item.saler_prov_take
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(agency_gross)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.toLocaleCurrency(
                                      item.agency_prov_purchase +
                                        item.agency_prov_take
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(note)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    item.tasks.length
                                      ? item.tasks.filter(
                                          (task) => task.task_type.value === 2
                                        ).length
                                      : 0
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(document)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    item.documents.length
                                      ? item.documents.length
                                      : 0
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(note_counter)",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip",
                                      value: _vm.toTooltipTask(
                                        item.tasks.filter(
                                          (task) => task.task_type.value === 2
                                        ),
                                        "Note"
                                      ),
                                      expression:
                                        "\n              toTooltipTask(\n                item.tasks.filter((task) => task.task_type.value === 2),\n                'Note'\n              )\n            ",
                                    },
                                  ],
                                  staticClass: "info-box float-left",
                                  attrs: {
                                    role: item.tasks.filter(
                                      (task) => task.task_type.value === 2
                                    ).length
                                      ? "button"
                                      : null,
                                  },
                                  on: {
                                    click: function ($event) {
                                      item.tasks.filter(
                                        (task) => task.task_type.value === 2
                                      ).length
                                        ? _vm.openNoteDetailModal(
                                            item.tasks.filter(
                                              (task) =>
                                                task.task_type.value === 2
                                            )
                                          )
                                        : null
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        item.tasks.filter(
                                          (task) => task.task_type.value === 2
                                        ).length
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(deferred)",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                [
                                  _vm.totalDeferred(item.entry_details)
                                    ? _c("b-icon", {
                                        attrs: {
                                          icon: "exclamation-circle-fill",
                                          variant: "danger",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(document_counter)",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip",
                                      value: _vm.toTooltipTask(
                                        item.documents,
                                        "Documenti",
                                        [
                                          { label: "Titoli", key: "title" },
                                          { label: "Descrizione", key: "note" },
                                          {
                                            label: "Ultimo aggiornamento",
                                            key: "last_update",
                                          },
                                        ]
                                      ),
                                      expression:
                                        "\n              toTooltipTask(item.documents, 'Documenti', [\n                { label: 'Titoli', key: 'title' },\n                { label: 'Descrizione', key: 'note' },\n                { label: 'Ultimo aggiornamento', key: 'last_update' },\n              ])\n            ",
                                    },
                                  ],
                                  staticClass: "info-box float-left",
                                  attrs: {
                                    role: item.documents.length
                                      ? "button"
                                      : null,
                                  },
                                  on: {
                                    click: function ($event) {
                                      item.documents.length
                                        ? _vm.openDocumentDetailModal(
                                            item.documents
                                          )
                                        : null
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.documents.length) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(cooperator)",
                          fn: function ({ item }) {
                            return [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.toInfoData(
                                      item.brokers.find(
                                        (e) => e.status_broker.value === 1
                                      ),
                                      "broker",
                                      1
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(broker)",
                          fn: function ({ item }) {
                            return [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.toInfoData(
                                      item.brokers.find(
                                        (e) => e.status_broker.value === 0
                                      ),
                                      "broker",
                                      0
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(entry_in)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.toLocaleCurrency(
                                      _vm.pos_entry(item, _vm.dbRowData.code)
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(entry_out)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.toLocaleCurrency(
                                      Math.abs(
                                        _vm.neg_entry(item, _vm.dbRowData.code)
                                      )
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(pos_rebate)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.toLocaleCurrency(
                                      _vm.pos_entry(item, "AB")
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    _vm.items.length
                      ? {
                          key: "cell(neg_rebate)",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.toLocaleCurrency(
                                      _vm.neg_entry(item, "AB")
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        }
                      : null,
                    {
                      key: "cell(actions)",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "action-buttons" },
                            [
                              _vm.actions.includes("infomodal")
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-1 mr-1",
                                      attrs: {
                                        size: "sm",
                                        variant: "lisaweb",
                                        title: "Modale Dettagli",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openModal(
                                            row.item,
                                            row.index,
                                            $event.target
                                          )
                                        },
                                      },
                                    },
                                    [_c("b-icon", { attrs: { icon: "eye" } })],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.actions.includes("edit")
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-1 mr-1",
                                      attrs: {
                                        size: "sm",
                                        variant: "lisaweb",
                                        title: "Modifica",
                                        disabled: _vm.hasBrokerStatement(
                                          row.item
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onEdit(row.item)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "pencil-square" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.actions.includes("details")
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-1 mr-1",
                                      attrs: {
                                        size: "sm",
                                        variant: "lisaweb",
                                        title: row.detailsShowing
                                          ? "Chiudi dettagli"
                                          : "Apri dettagli",
                                      },
                                      on: { click: row.toggleDetails },
                                    },
                                    [
                                      row.detailsShowing
                                        ? _c("b-icon", {
                                            attrs: { icon: "chevron-up" },
                                          })
                                        : _c("b-icon", {
                                            attrs: { icon: "chevron-down" },
                                          }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.actions.includes("destroy")
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-1 mr-1",
                                      attrs: {
                                        size: "sm",
                                        variant: "lisaweb",
                                        disabled:
                                          _vm.isImported(row.item) ||
                                          _vm.isInsurerAccounting(row.item) ||
                                          _vm.hasBrokerStatement(row.item),
                                        title: "Elimina",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDestroy(row.item.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "trash" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "thead-top",
                      fn: function (data) {
                        return Object.keys(_vm.dbRowData).length &&
                          _vm.items.length
                          ? _vm._l(data.fields, function (field, i) {
                              return _c(
                                "td",
                                { key: i, staticClass: "totalRow" },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        i == 0 && field.key === "book_date"
                                          ? ""
                                          : ""
                                      ) +
                                        "\n              " +
                                        _vm._s(
                                          i !== 0 &&
                                            field.key ===
                                              "additional_description"
                                            ? _vm.checkBalanceLabelbyDate
                                            : ""
                                        ) +
                                        "\n              " +
                                        _vm._s(
                                          i !== 0 && field.key === "entry_in"
                                            ? `${_vm.checkBalancebyDate("in")}`
                                            : null
                                        ) +
                                        "\n              " +
                                        _vm._s(
                                          i !== 0 && field.key === "entry_out"
                                            ? `${_vm.checkBalancebyDate("out")}`
                                            : null
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              )
                            })
                          : undefined
                      },
                    },
                    {
                      key: "custom-foot",
                      fn: function (data) {
                        return [
                          _vm.includeBottomRow && _vm.items.length
                            ? _vm._l(data.fields, function (field, i) {
                                return _c(
                                  "td",
                                  {
                                    key: i,
                                    staticClass:
                                      "totalRow totalRow--upper-pagination",
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(i === 0 ? "Totale: " : "") +
                                          "\n                " +
                                          _vm._s(
                                            i !== 0 && field.key === "gross"
                                              ? _vm.toLocaleCurrency(
                                                  _vm.fieldSum(
                                                    _vm.items,
                                                    "gross"
                                                  )
                                                )
                                              : null
                                          ) +
                                          "\n                " +
                                          _vm._s(
                                            i !== 0 &&
                                              field.key === "agency_gross"
                                              ? _vm.toLocaleCurrency(
                                                  _vm.fieldSum(
                                                    _vm.items,
                                                    "agency_prov_take"
                                                  ) +
                                                    _vm.fieldSum(
                                                      _vm.items,
                                                      "agency_prov_purchase"
                                                    )
                                                )
                                              : null
                                          ) +
                                          "\n                " +
                                          _vm._s(
                                            i !== 0 &&
                                              field.key === "saler_gross"
                                              ? _vm.toLocaleCurrency(
                                                  _vm.fieldSum(
                                                    _vm.items,
                                                    "saler_prov_take"
                                                  ) +
                                                    _vm.fieldSum(
                                                      _vm.items,
                                                      "saler_prov_purchase"
                                                    )
                                                )
                                              : null
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          Object.keys(_vm.dbRowData).length && _vm.items.length
                            ? _vm._l(data.fields, function (field, i) {
                                return _c(
                                  "td",
                                  {
                                    key: i,
                                    staticClass:
                                      "totalRow totalRow--upper-pagination",
                                  },
                                  [
                                    i !== 0 &&
                                    field.key === "additional_description"
                                      ? _c("span", {
                                          staticClass: "info",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              `Totale Movimenti<br><b>${_vm.checkFinalBalanceLabel}</b>`
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    i !== 0 && field.key === "entry_in"
                                      ? _c("span", {
                                          staticClass: "info",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              `${_vm.toLocaleCurrency(
                                                _vm.dbRowData.entry_in
                                              )}<br><b>${_vm.checkFinalBalance(
                                                "in"
                                              )}</b>`
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    i !== 0 && field.key === "entry_out"
                                      ? _c("span", {
                                          staticClass: "info",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              `${_vm.toLocaleCurrency(
                                                Math.abs(
                                                  _vm.dbRowData.entry_out
                                                )
                                              )}<br><b>${_vm.checkFinalBalance(
                                                "out"
                                              )}</b>`
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          Object.keys(_vm.extraFooter).length &&
                          _vm.items.length
                            ? _vm._l(data.fields, function (field, i) {
                                return _c(
                                  "td",
                                  { key: i, staticClass: "totalRow" },
                                  [
                                    i !== 0 &&
                                    field.key === "additional_description"
                                      ? _c("span", {
                                          staticClass: "info",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              `Totale Movimenti<br>${_vm.checkFinalBalanceLabel}`
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    i !== 0 && field.key === "pos_rebate"
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              `${
                                                _vm.toLocaleCurrency(
                                                  _vm.extraFooter.entry_in
                                                ) +
                                                "<br>" +
                                                (_vm.extraFooter.entry_in +
                                                  _vm.extraFooter.entry_out >
                                                0
                                                  ? _vm.toLocaleCurrency(
                                                      _vm.extraFooter.entry_in +
                                                        _vm.extraFooter
                                                          .entry_out
                                                    )
                                                  : "")
                                              }`
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    i !== 0 && field.key === "neg_rebate"
                                      ? _c("span", {
                                          staticClass: "info",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              `${
                                                _vm.toLocaleCurrency(
                                                  _vm.extraFooter.entry_out
                                                ) +
                                                "<br>" +
                                                (_vm.extraFooter.entry_in +
                                                  _vm.extraFooter.entry_out <
                                                0
                                                  ? _vm.toLocaleCurrency(
                                                      _vm.extraFooter.entry_in +
                                                        _vm.extraFooter
                                                          .entry_out
                                                    )
                                                  : "")
                                              }`
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                Object.keys(_vm.dbRowData).length && _vm.items.length
                  ? _c("template", { slot: "bottom-row" }, [
                      _c("td", { attrs: { colspan: "4" } }, [
                        _c("div", { staticClass: "row pagination--intable" }, [
                          _c("div", { staticClass: "show-text" }, [
                            _c("p", [_vm._v("Mostra")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "group" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-for": "per-page-select",
                                    "label-cols-sm": "3",
                                    "label-cols-md": "",
                                    "label-cols-lg": "3",
                                    "label-size": "sm",
                                    md: "auto",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    staticStyle: { width: "68px" },
                                    attrs: {
                                      id: "per-page-select",
                                      options: _vm.pageOptions,
                                      size: "sm",
                                      disabled: !_vm.items.length,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onPageChange(1)
                                      },
                                    },
                                    model: {
                                      value: _vm.perPage,
                                      callback: function ($$v) {
                                        _vm.perPage = $$v
                                      },
                                      expression: "perPage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "page" }, [
                            _c("p", [
                              _vm._v(
                                "di " + _vm._s(_vm.totalItems) + " per pagina"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.totalItems
                            ? _c(
                                "div",
                                { staticClass: "pagination" },
                                [
                                  _c("b-pagination", {
                                    attrs: {
                                      limit: "3",
                                      "total-rows": _vm.totalItems,
                                      "per-page": _vm.perPage,
                                      size: "sm",
                                    },
                                    on: { change: _vm.onPageChange },
                                    model: {
                                      value: _vm.currentPage,
                                      callback: function ($$v) {
                                        _vm.currentPage = $$v
                                      },
                                      expression: "currentPage",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.totalItems && !_vm.noInputPaginator
                            ? _c(
                                "div",
                                { staticClass: "input_pagination" },
                                [
                                  _c("base-input-pagination", {
                                    on: { pagination: _vm.onInputPagination },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.noPagination &&
        !(Object.keys(_vm.dbRowData).length && _vm.items.length)
          ? _c("b-row", [
              _c("div", { staticClass: "show-text" }, [
                _c("p", [_vm._v("Mostra")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "group" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-for": "per-page-select",
                        "label-cols-sm": "3",
                        "label-cols-md": "",
                        "label-cols-lg": "3",
                        "label-size": "sm",
                        md: "auto",
                      },
                    },
                    [
                      _c("b-form-select", {
                        staticStyle: { width: "68px" },
                        attrs: {
                          id: "per-page-select",
                          options: _vm.pageOptions,
                          size: "sm",
                          disabled: !_vm.items.length,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onPageChange(1)
                          },
                        },
                        model: {
                          value: _vm.perPage,
                          callback: function ($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "page" }, [
                _c("p", [
                  _vm._v("di " + _vm._s(_vm.totalItems) + " per pagina"),
                ]),
              ]),
              _vm._v(" "),
              _vm.totalItems
                ? _c(
                    "div",
                    { staticClass: "pagination" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          limit: "3",
                          "total-rows": _vm.totalItems,
                          "per-page": _vm.perPage,
                          size: "sm",
                        },
                        on: { change: _vm.onPageChange },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.totalItems && !_vm.noInputPaginator
                ? _c(
                    "div",
                    { staticClass: "input_pagination" },
                    [
                      _c("base-input-pagination", {
                        on: { pagination: _vm.onInputPagination },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            attrs: {
              id: _vm.infomodalName,
              title: _vm.modalTitle,
              size: "xl",
              scrollable: "",
              "ok-only": "",
              "ok-variant": "lisaweb",
              "header-bg-variant": "lisaweb",
              "no-close-on-backdrop": "",
            },
            on: { hidden: _vm.onModalHidden },
          },
          [
            _c(
              "div",
              [
                _c(
                  "b-tabs",
                  {
                    attrs: { "content-class": "pt-1", lazy: "" },
                    model: {
                      value: _vm.tabIndex,
                      callback: function ($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex",
                    },
                  },
                  _vm._l(_vm.tabs, function (tab, index) {
                    return _c(
                      "b-tab",
                      {
                        key: index,
                        attrs: {
                          title: tab.text,
                          active: index === _vm.tabIndex,
                          "title-item-class": "lisaweb",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setTab(tab, index)
                          },
                        },
                      },
                      [
                        _c("button-group-tab"),
                        _vm._v(" "),
                        _c(
                          "keep-alive",
                          [
                            _vm.selected === tab.name
                              ? _c(_vm.selected, {
                                  tag: "component",
                                  attrs: { resourceId: _vm.modalResourceId },
                                  on: {
                                    fetch: function ($event) {
                                      return _vm.updatedData()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }